<template>
  <section
    class="section"
  >
    <h2
      v-if="props.title"
      class="section__title"
    >{{ title }}</h2>

    <div class="section__content">
      <slot></slot>
    </div>
    <img
      class="section__image"
      :src="`/backgrounds/${image}.jpg`"
      :alt="props.title"
      loading="lazy"
    >
  </section>
</template>
<script setup>
const props = defineProps({
  title: {
    type: String,
  },
  image: {
    type: String,
    default: 'default'
  },
})
const image = props.image || 'default'
</script>
<style scoped>
.section {
  position: relative;
  padding: var(--gap-lg);
  border-bottom: .4rem solid var(--color-main-500);
}
.section__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  filter: blur(.3rem);
  opacity: .3;
  display: none;
}
.section__title {
  margin-bottom: var(--gap);
  letter-spacing: .5rem;
  font-size: clamp(3rem, 8vw, 4rem);
  font-weight: 900;
  text-align: center;
}
@media (max-width: 500px) {
  .section {
    padding: var(--gap);
  }

}
.section__content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}
.section__title,
.section__content {
  position: relative;
  z-index: 1;
}
</style>