<template>
  <div
    class="card"
    :class="[props.variant ? `card--${props.variant}` : '', `card--${props.size}`, `card--${props.image ? 'has-image' : 'no-image'}`]"
  >
    <img
      v-if="props.image"
      class="card__image"
      :src="`/images/${props.image}.jpg`"
      :alt="props.title"
      loading="lazy"
    >
    <div class="card__content">
      <h3
        v-if="props.title"
        class="card__title"
      >{{ props.title }}</h3>
      <Divider
        v-if="props.title && !props.image && hasSlotContent"
        :align="props.variant === 'list' ? 'left' : 'center'"
      />
      <div class="card__slot">
        <slot></slot>
      </div>
      <div class="card__link" v-if="url">
        <Button :to="url" variant="tertiary">{{ props.buttonLabel || 'Saber más' }}</Button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, useSlots } from 'vue'

const props = defineProps({
  image: String,
  title: String,
  href: String,
  to: String,
  buttonLabel: String,
  variant: {
    type: String,
    validator: value => {
      return ['list', 'default'].includes(value)
    },
  },
  size: {
    type: String,
    validator: value => {
      return ['md', 'lg'].includes(value)
    },
    default: 'md'
  },
})

const url = ref(null)
const hasSlotContent = ref(null)
onMounted(() => {
  url.value = props.href || props.to

  const slotContent = useSlots().default
  hasSlotContent.value = !!slotContent
})

</script>
<style>
.card--list .card__slot > ul {
  list-style-type: disc;
  padding-left: var(--gap);
}
.card .card__slot h4 {
  font-size: 2rem;
  margin-top: var(--gap-sm);
}
</style>
<style scoped>
.card {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  max-width: 32rem;
  min-width: 25rem;
  min-height: 14rem;
  width: 100%;
  background-color: var(--color-neutral-900);
  opacity: .9;
  font-size: 1.6rem;
  border-radius: .8rem;
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,.2);
  color: #FFF;
}
.card--no-image {
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.card--list {
  text-align: left;
}

.card--lg {
  max-width: 70rem;
}

.card__content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--gap-sm);
  gap: var(--gap-sm);
  line-height: 1.4;
}
.card--has-image .card__content {
  flex-grow: 1;
  padding-top: 15rem;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 5%, rgba(0,0,0,0.8) 100%);
}
.card--lg .card__content {
  padding-bottom: var(--gap);
  padding-left: var(--gap);
  padding-right: var(--gap);
  font-size: 1.6rem;
}

.card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card__title {
  display: flex;
  align-items: flex-end;
  font-size: 2rem;
  flex-grow: 1;
}
.card--no-image:not(.card--list) .card__title {
  justify-content: center;
}

.card__slot {
  display: flex;
  align-items: flex-end;
  gap: var(--gap-sm);
  flex-grow: 1;
}
.card--no-image .card__slot {
  flex-direction: column;
  align-items: center;
}
.card__slot:empty {
  display: none;
}

.card__link {
  text-align: right;
}
.card--no-image .card__link {
  text-align: center;
}
</style>